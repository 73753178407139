import React, { ReactNode } from 'react';
import { graphql, Link } from 'gatsby';
import { Box, Container, styled, Typography } from '@mui/material';
import rehypeReact from 'rehype-react';

import { Album, Layout, Menu, DashedBorder } from '../components';
import { colors } from '../theme';

interface rehypeProps {
  children?: ReactNode | ReactNode[];
}

const linkStyle = {
  color: colors.lightGreen,
  fontWeight: 600,
  textDecoration: 'none',
  transition: 'color .2s ease-in',
  '&:hover': {
    color: colors.darkRed,
  },
};

const StyledLink = styled(Link)(() => linkStyle);
const StyledA = styled('a')(() => linkStyle);

const MDImage = (props) => {
  const { className } = props;
  const { children, ...restProps } = props;
  if (className !== 'gatsby-resp-image-link') {
    const { href } = restProps;
    if (href.startsWith('http')) {
      return (
        <StyledA {...restProps} rel="noreferrer" target="_blank">
          {children}
        </StyledA>
      );
    } else {
      return <StyledLink to={href}>{children}</StyledLink>;
    }
  }
  const { title, ...childProps } = children[3].props;
  childProps.style.borderRadius = 4;
  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <Box maxWidth="sm" position="relative" flex="1">
        <picture>
          {children[1]}
          <img alt="" {...childProps} />
        </picture>
      </Box>
    </Box>
  );
};

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: MDImage,
    h1: ({ children }: rehypeProps) => (
      <Typography variant="h1" paragraph>
        {children}
      </Typography>
    ),
    h2: ({ children }: rehypeProps) => (
      <Typography variant="h2" paragraph>
        {children}
      </Typography>
    ),
    h3: ({ children }: rehypeProps) => (
      <Typography variant="h3" paragraph>
        {children}
      </Typography>
    ),
    h4: ({ children }: rehypeProps) => (
      <Typography variant="h4" paragraph>
        {children}
      </Typography>
    ),
  },
}).Compiler;

const IndexPage = ({ data, ...props }) => {
  const { content, pattern, bottom } = data;
  const { frontmatter, htmlAst } = content;
  const { title, images } = frontmatter;

  const ogImage = images?.[0]?.publicURL;

  return (
    <Layout ogTitle={title} ogImage={ogImage}>
      <Container maxWidth={false} disableGutters>
        <Container maxWidth="lg">
          <Menu />
        </Container>
        <Container maxWidth="md">
          <Box mt={4} mb={2} display="flex" justifyContent="center">
            <Typography variant="h1" textAlign="center">
              {title}
            </Typography>
          </Box>
          <DashedBorder />
          <Box mt={4}>{renderAst(htmlAst)}</Box>
          <Box mt={4}>
            <Album images={images} />
          </Box>
        </Container>

        {pattern?.htmlAst && (
          <Container
            maxWidth={false}
            disableGutters
            sx={{ backgroundColor: `rgba(38, 126, 150, 0.2)` }}
          >
            <Container maxWidth="md">
              <Box mt={4} pt={4} pb={4}>
                {renderAst(pattern.htmlAst)}
              </Box>
            </Container>
          </Container>
        )}
        {bottom?.htmlAst && (
          <Container maxWidth="md">
            <Box mt={4} pt={4} pb={4}>
              {renderAst(bottom.htmlAst)}
            </Box>
          </Container>
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query PortfolioPageQuery(
    $slug: String!
    $patternPathRegex: String!
    $bottomPathRegex: String!
  ) {
    content: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      id
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        slug
        title
        images {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
          extension
        }
        pattern
        patternUrl
        yarn
        yarnUrl
      }
    }
    pattern: markdownRemark(fileAbsolutePath: { regex: $patternPathRegex }) {
      htmlAst
    }
    bottom: markdownRemark(fileAbsolutePath: { regex: $bottomPathRegex }) {
      htmlAst
    }
  }
`;
